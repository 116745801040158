<template>
  <div
    v-show="show"
    v-hotkey="keymap"
    v-test-id="'search-input'"
    class="search-input"
    :class="{
      'search-input--spotlight': spotlight,
    }"
  >
    <div
      v-if="show"
      ref="searchCard"
      v-click-outside="closeExpandedSearch"
      class="search-input__card"
      :class="{
        'search-input__card--expanded': expanded,
        'search-input__card--spotlight': spotlight,
      }"
    >
      <SearchInputTextInput
        v-model="searchTerm"
        :expanded="expanded"
        :placeholder="placeholder"
        :autofocus="spotlight"
        @click="!expanded ? toggle() : resetSelection()"
        @focus="!expanded ? toggle() : resetSelection()"
        @key-pressed="onTextInputKeyPressed"
        @close="closeExpandedSearch"
      />

      <v-expand-transition>
        <div
          v-if="expanded"
          class="search-input__drawer"
        >
          <div class="search-input-drawer__items">
            <SearchInputResult
              v-if="searchTerm !== ''"
              :result="result"
              @item-clicked="toggle"
            />

            <SearchInputRecent @item-clicked="toggle" />
          </div>

          <SearchInputFooter class="search-input-drawer__footer" />
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { everyItemOfArrayShouldHave } from 'vue-prop-validation-helper';
import { isMac, LocalSearchStrategy, SearchEngine } from '~/assets/javascript/utils';
import SearchInputTextInput from './_text-input';
import SearchInputRecent from './_recent';
import SearchInputResult from './_result';
import SearchInputFooter from './_footer';

export default defineComponent({
  name: 'SearchInput',
  components: {
    SearchInputTextInput,
    SearchInputRecent,
    SearchInputResult,
    SearchInputFooter,
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          // here I check that click was outside the el and his children
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event.target);
          }
        };

        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  props: {
    searchableData: {
      type: Array,
      default: () => ([]),
      validator: everyItemOfArrayShouldHave(['id', 'type', 'title', 'subtitle', 'label', 'to']),
    },
    placeholder: { type: String, default: undefined },
    spotlight: { type: Boolean, default: false },
    spotlightOpen: { type: Boolean, default: false },
  },
  emits: ['update:spotlightOpen'],
  data() {
    return {
      selectedElementIndex: null,
      keyboardSelectableElements: [],
      expanded: false,
      searchTerm: '',
    };
  },
  computed: {
    show() {
      if (this.spotlight) return this.expanded;

      return true;
    },
    searchStrategy() {
      return new LocalSearchStrategy({
        items: this.searchableData,
        searchableKeys: [
          { name: 'title', weight: 10 },
          { name: 'subtitle', weight: 3 },
        ],
      });
    },
    searchResult() {
      const searchEngine = new SearchEngine(this.searchStrategy);
      return searchEngine.search(this.searchTerm);
    },
    result() {
      if (_isEmpty(this.searchTerm)) return {};
      // Group the items by their pluralized 'type' property. Ex: [view1, view2, workflow1, sheet1] turns into { views: [ view1, view2 ], workflows: [ workflow1 ], sheets: [ sheet1 ]}
      return this.searchResult.result.reduce((acc, item) => {
        const { type } = item;
        const pluralizedType = `${type}s`;

        // If the pluralized 'type' key doesn't exist in the accumulator, create an array for it
        if (!acc[pluralizedType]) {
          acc[pluralizedType] = [];
        }

        // Add the item to the corresponding array
        acc[pluralizedType].push(item);

        return acc;
      }, {});
    },
    keymap() {
      return this.expanded ? {
        esc: this.closeExpandedSearch,
        [isMac() ? 'command+k' : 'ctrl+k']: this.toggle,
        up: this.activateElementAbove,
        down: this.activateElementBelow,
        tab: this.activateElementBelow,
        'shift+tab': this.activateElementAbove,
        enter: () => {
          if (this.selectedElementIndex !== null) this.closeExpandedSearch();
        },
      } : {
        [isMac() ? 'command+k' : 'ctrl+k']: this.toggle,
      };
    },
  },
  watch: {
    async spotlightOpen(value) {
      if (!this.spotlight || value === this.expanded) return;

      this.expanded = value;

      if (value) {
        await this.$nextTick();

        document.querySelector('.js-search-input-text-input input:first-of-type').focus();
      }
    },
    expanded(value) {
      if (value) {
        this.resetSelection();
      } else {
        this.search('');
      }

      if (this.spotlight && value !== this.spotlightOpen) {
        this.$emit('update:spotlightOpen', value);
      }
    },
    selectedElementIndex(value) {
      nextTick().then(() => {
        if (this.selectedElementIndex >= 0) {
          this.setKeyboardSelectableElements();
          this.keyboardSelectableElements[value]?.focus();
        }
      });
    },
    result() {
      this.resetSelection();
    },
  },
  mounted() {
    this.$el.addEventListener('keydown', this.onKeydown);
  },
  unmounted() {
    this.$el.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    search(searchTerm) {
      this.searchTerm = searchTerm;
    },
    toggle() {
      this.expanded = !this.expanded;
    },
    setKeyboardSelectableElements() {
      this.keyboardSelectableElements = this.getKeyboardSelectableElements();
    },
    resetSelection() {
      nextTick().then(() => {
        this.selectedElementIndex = null;
        this.setKeyboardSelectableElements();
      });
    },
    closeExpandedSearch() {
      this.search('');
      this.expanded = false;
    },
    onKeydown($event) {
      const bypassKeys = ['Tab', 'ArrowDown', 'ArrowUp', 'Escape', 'Enter']; // these are mapped through v-hotkey

      if (bypassKeys.includes($event.key)) return;

      if ($event.metaKey) return;

      this.$el.querySelector('input').focus();
    },
    onTextInputKeyPressed($event) { // this is necessary because v-hotkey doesn't work when input is focused
      switch ($event.key) {
        case 'ArrowDown':
          this.activateElementBelow();
          break;
        case 'ArrowUp':
          this.activateElementAbove();
          break;
        case 'Tab':
          if ($event.shiftKey) this.activateElementAbove();
          else this.activateElementBelow();
          $event.preventDefault();
          break;
        case 'k':
          this.toggle();
          $event.preventDefault();
          break;
        case 'Escape':
          this.closeExpandedSearch();
          break;
        default:
          break;
      }

      $event.stopPropagation();
    },
    activateElementBelow() {
      if (!this.expanded) return;
      if (this.selectedElementIndex === null) this.selectedElementIndex = 0;
      else this.selectedElementIndex = (this.selectedElementIndex + 1) % this.keyboardSelectableElements.length;
    },
    activateElementAbove() {
      if (!this.expanded) return;
      if (this.selectedElementIndex === null) this.selectedElementIndex = this.keyboardSelectableElements.length - 1;
      else this.selectedElementIndex = (this.selectedElementIndex - 1 + this.keyboardSelectableElements.length) % this.keyboardSelectableElements.length;
    },
    getKeyboardSelectableElements() {
      return [...this.$el.querySelectorAll('.v-list-item')];
    },
  },
});
</script>

<style lang="scss">
@use '~/assets/styles/settings/vuetify-settings.scss';

.search-input {
  --search-height: 30px;
  --search-border-width: var(--z-input-border-width);
  --search-transition: 0.2s cubic-bezier(0.5,0,0.1,1);

  // Having the min-width the same value as its height will keep an 1:1 aspect
  // ratio when the available space is too small. So it behaves like an icon button
  min-width: var(--search-height);

  @media (min-width: 600px) {
    --search-height: 40px;
  }

  .expand-transition-leave-active,
  .expand-transition-enter-active {
    transition: var(--search-transition) !important;
  }

  & .v-field__outline {
    display: none;
  }
}

.search-input--spotlight {
  backdrop-filter: blur(5px);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: backdrop-filter 1s ease-in-out;
  width: 100vw;
  z-index: 205;

  input::placeholder {
    text-align: start !important;
  }
}

// hides the footer on mobile
.search-input-drawer__footer {
  background: var(--z-theme-background);
  position: sticky;
  border-bottom-left-radius: var(--z-border-radius-base);
  border-bottom-right-radius: var(--z-border-radius-base);
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
}

.search-input__card {
  background: var(--z-theme-background);
  border-radius: 0;
  box-shadow: none !important; // v-card override
  overflow: visible;
  position: relative;
  transition: var(--search-transition) !important; // v-card override
  width: 640px;
  z-index: 1 !important;

  &:hover {
    border-color: var(--z-color-gray) !important;
  }

  @media (min-width: 600px) {
    border-radius: var(--z-border-radius-base) !important;
    max-width: 50dvw !important;
  }
}

.search-input__card--spotlight {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  left: 0;
  top: 0;
  position: absolute;

  @media (min-width: 600px) {
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    box-shadow: 0 var(--z-s2) var(--z-s4) 0 var(--z-elevation-color) !important;
  }
}

.search-input__card--expanded {
  & .v-field {
    border: var(--search-border-width) solid var(--z-theme-background-secondary);
    border-radius: 0 !important;

    @media (min-width: 600px) {
      border-top-left-radius: var(--z-border-radius-inner-base) !important;
      border-top-right-radius: var(--z-border-radius-inner-base) !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &, &:hover {
    outline-color: var(--z-elevation-color) !important;
  }

  @media (max-width: 840px) {
    --search-height: 56px;

    border-radius: 0 !important;
    margin-left: var(--offset-x-search-card);
    margin-top: var(--offset-y-search-card);
    width: 100vw;
  }
}

.search-input__drawer {
  background-color: var(--z-theme-surface);
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;

  @media (min-width: 600px) {
    border-bottom-left-radius: var(--z-border-radius-base);
    border-bottom-right-radius: var(--z-border-radius-base);
    border-bottom: var(--search-border-width) solid var(--z-theme-background-secondary);
    border-left: var(--search-border-width) solid var(--z-theme-background-secondary);
    border-right: var(--search-border-width) solid var(--z-theme-background-secondary);
    box-shadow: 0 var(--z-s3) var(--z-s4) 0 var(--z-theme-shadow) !important;
  }
}

.search-input-drawer__items {
  height: calc(100vh - var(--search-height));
  overflow-y: auto;

  @media (min-width: 840px) {
    height: unset;
    max-height: 40vh;
  }

  & .v-list {
    border-radius: 4px !important;
  }
}
</style>
