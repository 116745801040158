<i18n lang="yaml">
pt:
  seeMore: 'Ver mais'
en:
  seeMore: 'See more'
</i18n>

<template>
  <v-list
    density="compact"
    class="searchable-items-list"
  >
    <v-list-subheader
      v-if="subheader"
      class="searchable-items-list__subheader"
    >
      {{ subheader }}
    </v-list-subheader>
    <SearchableItem
      v-for="item in displayItems"
      v-bind="item"
      :key="item.id"
      @click="$emit('itemClicked', item)"
    />
    <v-list-item
      v-if="canExpand"
      link
      class="searchable-items-list-subheader__expand"
      @click="expand"
      @keydown="keydownExpand"
    >
      <span>
        {{ t('seeMore') }}
      </span>
    </v-list-item>
  </v-list>
</template>

<script>
import SearchableItem from './_searchable-item';

export default defineComponent({
  name: 'SearchableItemsList',
  components: {
    SearchableItem,
  },
  props: {
    subheader: { type: String, default: null },
    items: { type: Array, default: () => [] },
    truncateAt: { type: Number, default: null },
  },
  emits: ['itemClicked'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    displayItems() {
      return this.canExpand ? this.items.slice(0, this.truncateAt) : this.items;
    },
    canExpand() {
      return this.truncateAt && this.items.length > this.truncateAt && !this.expanded;
    },
  },
  methods: {
    keydownExpand($event) {
      if ($event.key === 'Enter') {
        this.expand($event);
      }
    },
    expand($event) {
      this.expanded = true;

      // stop event propagation to avoid triggering the click event on the item
      $event.stopPropagation();
    },
  },
});
</script>

<style lang="scss">
.searchable-items-list {
  height: calc(100vh - var(--search-height));
  overflow-y: hidden;
  padding: 0;

  @media (min-width: 840px) {
    height: unset;
  }
}

.searchable-items-list__subheader {
  &.v-list-subheader {
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    margin-top: 8px;
    padding: 0;
  }
}

.searchable-items-list-subheader__expand {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0 4px;
  padding: 0 30px !important;
  border-radius: 4px;

  & span {
    font-weight: 800;
    color: rgb(var(--v-theme-primary))
  }

  &.v-list-item {
    min-height: 20px;
    padding-top: 0px;

    &:focus,
    &:hover {
      color: rgb(var(--v-theme-primary)) !important;
      border-radius: 2px;
    }
  }
}
</style>
